import React from 'react';

import * as Styled from './styles';
import { StyledProps } from './styles';

interface Props extends StyledProps {
	title: string;
	subtitle?: string;
}

const TitleSection: React.FC<Props> = ({ center, title, subtitle }) => (
	<Styled.TitleSection>
		<Styled.Title center={center}>{title}</Styled.Title>
		{subtitle && <Styled.SubTitle center={center}>{subtitle}</Styled.SubTitle>}
		<Styled.Separator center={center} />
	</Styled.TitleSection>
);

export default TitleSection;
