import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

interface HochzeitsodyseePost {
	html: React.ReactNode;
	fields: {
		slug: string;
	};
	frontmatter: {
		title: string;
	};
}

interface Props {
	data: {
		markdownRemark: HochzeitsodyseePost;
	};
	pageContext: {
		slug: string;
		next: HochzeitsodyseePost;
		previous: HochzeitsodyseePost;
	};
}

const HochzeitsodyseePost: React.FC<Props> = ({ data, pageContext }) => {
	const post = data.markdownRemark;
	const { previous, next } = pageContext;

	return (
		<Layout>
			<SEO title={post.frontmatter.title} />
			<Container section>
				<TitleSection title={post.frontmatter.title} />
				<FormatHtml content={post.html} />
				<Styled.Links>
					<span>
						{previous && (
							<Link to={previous.fields.slug} rel="previous">
								← {previous.frontmatter.title}
							</Link>
						)}
					</span>
					<span>
						{next && (
							<Link to={next.fields.slug} rel="next">
								{next.frontmatter.title} →
							</Link>
						)}
					</span>
				</Styled.Links>
			</Container>
		</Layout>
	);
};

export default HochzeitsodyseePost;

export const query = graphql`
	query HochzeitsodyseePostBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
			}
		}
	}
`;
